import React, { useState, useRef, useEffect } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { gsap, Power1 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
// import readingTime from 'reading-time';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import {
  StyledArticle,
  ArticleHead,
  Tags,
  TagContainer,
  Tag,
  ArticleHeadDetails,
  Title,
  RelatedArticlesWrapper,
  MediaAssetContainer,
  TextContentWrapper,
  ReadingTimeStyled,
  DateStyled,
  SocialLinks,
  SocialLinkContainer,
  SocialLinksMobileWrapper,
  SocialLinksWrapper,
  ClipboardText,
  StyledVideo,
  TitleWrapper,
} from './styles';
import Container from 'components/Container';
import RelatedArticles from 'components/RelatedArticles';
import TextContent from 'components/TextContent';
import MediaAsset from 'components/UI/MediaAsset';
import Icon from 'components/Icon';
import BackToTop from 'components/UI/BackToTop';
import CustomSplitText from 'components/CustomSplitText';
import { formatDateToFr } from 'utils/functions/formatDateToFr';
import ReactPlayer from 'react-player/lazy';
import titleAnimations from 'utils/animations/titleAnimations';
import { useDeviceDetect } from 'utils/hooks/useDeviceDetect';

gsap.registerPlugin(ScrollTrigger);

const Article = (props) => {
  const { path, location } = props;
  const {
    articleTheme,
    title,
    tags,
    date: enDate,
    asset,
    placeholderAsset,
    body,
    youtubeVideo,
  } = props?.data?.contentfulArticlePage;
  const { edges } = props?.data?.allContentfulArticlePage;
  const [isVisibleText, setIsVisibleText] = useState(false);
  const date = formatDateToFr(enDate);
  const toTopName = 'Retour en haut de page';
  const { isMobile } = useDeviceDetect();

  let lastIndex = path.lastIndexOf('/');
  const preparedPath = path.slice(0, lastIndex);

  const thematicArticles = (check) =>
    edges.filter((ed) => ed?.node?.articleTheme?.title === check);

  const rawText = body?.raw
    ? documentToReactComponents(JSON.parse(body?.raw))
        ?.map((c) => c.props.children)
        ?.flat()
        ?.filter((c) => typeof c !== 'object')
        ?.join('')
    : null

  // const stats = rawText ? readingTime(rawText) : '';

  const CopyToClipboard = (toCopy) => {
    const el = document.createElement(`textarea`);
    el.value = toCopy;
    el.setAttribute(`readonly`, ``);
    el.style.position = `absolute`;
    el.style.left = `-9999px`;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };

  const handleClipBoardClick = (value) => {
    CopyToClipboard(value);
    setIsVisibleText(true);
    setTimeout(() => setIsVisibleText(false), 3000);
  };

  const articleHeadRef = useRef();
  const titleRef = useRef();
  const tl = useRef();

  useEffect(() => {
    const titleTl = titleAnimations(titleRef);
    const articleImg =
      articleHeadRef.current?.querySelector('#article-head-img');

    tl.current = gsap
      .timeline()
      .fromTo(
        articleHeadRef.current,
        { y: '30%' },
        { y: 0, duration: 0.7, ease: Power1.easeOut },
        0.1
      )
      .fromTo(
        articleImg,
        { autoAlpha: 0 },
        { autoAlpha: 1, duration: 0.9, ease: Power1.easeOut },
        0
      )
      .add(titleTl, '-=0.5');

    return () => {
      tl.current.kill();
    };
  }, []);

  return (
    <StyledArticle>
      <SocialLinksWrapper>
        <SocialLinks className="SocialLinks">
          <SocialLinkContainer className="SocialLinkContainer">
            <FacebookShareButton url={location.href} quote={title}>
              <Icon className="StyledIcon" type="facebook" />
            </FacebookShareButton>
          </SocialLinkContainer>

          <SocialLinkContainer className="SocialLinkContainer">
            <TwitterShareButton url={location.href} quote={title}>
              <Icon className="StyledIcon" type="twitter" />
            </TwitterShareButton>
          </SocialLinkContainer>

          <SocialLinkContainer
            className="SocialLinkContainer SocialLinkClipboard"
            onClick={() => handleClipBoardClick(window.location.href)}
          >
            <Icon className="IconClipboard" type="link" />
            <ClipboardText className={isVisibleText ? 'isVisible' : ''}>
              Lien copié
            </ClipboardText>
          </SocialLinkContainer>
        </SocialLinks>
      </SocialLinksWrapper>
      <Container
        topSpacingTablet="99"
        topSpacingDesktop="156"
        bottomSpacingMobile="60"
        bottomSpacingTablet="90"
        bottomSpacingDesktop="90"
      >
        <ArticleHead ref={articleHeadRef}>
          <Tags>
            {tags &&
              tags.map((tag, index) => {
                return (
                  <TagContainer key={index}>
                    <Tag theme="eyebrow-text">{tag}</Tag>
                  </TagContainer>
                );
              })}
          </Tags>

          {title && (
            <TitleWrapper ref={titleRef}>
              <Title aria-label={title}>
                <CustomSplitText>{title}</CustomSplitText>
              </Title>
            </TitleWrapper>
          )}

          <ArticleHeadDetails>
            {date && <DateStyled>{date}</DateStyled>}
            {/* {stats?.minutes && (
              <ReadingTimeStyled>
                <Icon type="clock" />
                {`${Math.ceil(stats?.minutes)} min.`}
              </ReadingTimeStyled>
            )} */}
          </ArticleHeadDetails>

          <SocialLinksMobileWrapper>
            <SocialLinks className="SocialLinks">
              <SocialLinkContainer className="SocialLinkContainer">
                <FacebookShareButton url={location.href} quote={title}>
                  <Icon className="StyledIcon" type="facebook" />
                </FacebookShareButton>
              </SocialLinkContainer>

              <SocialLinkContainer className="SocialLinkContainer">
                <TwitterShareButton url={location.href} quote={title}>
                  <Icon className="StyledIcon" type="twitter" />
                </TwitterShareButton>
              </SocialLinkContainer>

              <SocialLinkContainer
                className="SocialLinkContainer SocialLinkClipboard"
                onClick={() => handleClipBoardClick(window.location.href)}
              >
                <Icon className="IconClipboard" type="link" />
                <ClipboardText className={isVisibleText ? 'isVisible' : ''}>
                  Lien copié
                </ClipboardText>
              </SocialLinkContainer>
            </SocialLinks>
          </SocialLinksMobileWrapper>

          <div id="article-head-img" style={{ width: '100%' }}>
            {asset.gatsbyImageData && !youtubeVideo ? (
              <MediaAssetContainer>
                <MediaAsset image={asset}></MediaAsset>
              </MediaAssetContainer>
            ) : placeholderAsset && asset?.file?.url?.includes('videos') ? (
              <MediaAssetContainer id="article-head-img">
                <StyledVideo
                  poster={placeholderAsset?.file?.url}
                  controls={true}
                >
                  <source src={asset?.file?.url} type="video/mp4" />
                </StyledVideo>
              </MediaAssetContainer>
            ) : youtubeVideo ? (
              <MediaAssetContainer id="article-head-img">
                <ReactPlayer
                  width={'100%'}
                  height={isMobile ? '300px' : '600px'}
                  url={youtubeVideo}
                  controls={true}
                  config={{
                    youtube: {
                      playerVars: { showinfo: 0 },
                      embedOptions: { modestbranding: 1 },
                    },
                  }}
                />
              </MediaAssetContainer>
            ) : (
              <></>
            )}
          </div>
        </ArticleHead>

        <TextContentWrapper>
          <TextContent
            body={body}
            capMobile="20"
            capTablet="584"
            capDesktop="900"
          />
          <BackToTop name={toTopName} />
        </TextContentWrapper>
      </Container>

      <RelatedArticlesWrapper>
        <RelatedArticles
          path={preparedPath}
          articles={thematicArticles(articleTheme.title)}
        />
      </RelatedArticlesWrapper>
    </StyledArticle>
  );
};

export default Article;
