import React from 'react';
import ArticleCard from 'components/ArticleCard';
import { SwiperSlide } from 'swiper/react';
import {
  StyledRelatedArticles,
  RelatedArticlesWrapper,
  RelatedArticlesTitle,
  StyledArticlesSliderWrapper,
  StyledArticlesSliderContainer,
  StyledArticlesSlider,
} from './styles';

const RelatedArticles = ({ articles, path }) => {
  const SlideStyle = {
    width: 'fit-content',
    height: 'auto',
    display: 'flex',
    overflow: 'hidden',
  };

  return articles?.length > 0 ? (
    <StyledRelatedArticles>
      <RelatedArticlesTitle>Articles apparentés</RelatedArticlesTitle>
      <RelatedArticlesWrapper>
        <StyledArticlesSliderWrapper>
          <StyledArticlesSliderContainer>
            <StyledArticlesSlider
              slideVisibleClass={true}
              slidesPerView={'auto'}
              spaceBetween={24}
              slidesOffsetAfter={30}
              scrollbar={false}
              navigation={false}
              freeMode={true}
              breakpoints={{
                600: {
                  spaceBetween: 30,
                  slidesOffsetAfter: 60,
                },
                1200: {
                  spaceBetween: 30,
                  slidesOffsetAfter: 0,
                },
              }}
            >
              {articles &&
                articles.slice(0, 4).map((item, index) => (
                  <SwiperSlide key={index} style={SlideStyle}>
                    <ArticleCard
                      key={item.node.id}
                      height="200px"
                      heightMobile="160px"
                      color="white"
                      path={path}
                      article={item.node}
                    />
                  </SwiperSlide>
                ))}
            </StyledArticlesSlider>
          </StyledArticlesSliderContainer>
        </StyledArticlesSliderWrapper>
      </RelatedArticlesWrapper>
    </StyledRelatedArticles>
  ) : (
    <></>
  );
};

export default RelatedArticles;
